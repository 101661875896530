.image-gallery-icon {
    color: #26a1c9;
    transition: all .3s ease-out;
    appearance: none;
    background-color: transparent;
    border: 6;
    cursor: pointer;
    outline: none;
    position: absolute;
    z-index: 2;
    filter: drop-shadow(0 2px 2px #1a1a1a)
}