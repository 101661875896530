.react-player {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

.player-wrapper {
  width: auto;
  height: auto;
}

.react-player {
  padding-top: 56.25%;
  position: relative;
}

.react-player>div {
  position: absolute;
}
